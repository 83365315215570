<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 text-left>
        <span class="mainHeader">BOARDING RATE</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 py-6 text-left align-self-center>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap justify-center py-6 v-if="dataList">
            <v-flex xs8 align-self-center>
              <v-layout wrap px-4 justify-center>
                <v-flex xs2 class="tablefont" align-self-center>Date : </v-flex>
                <v-flex xs4 align-self-center>
                  <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="#3F033B"
                    v-model="newdate"
                    placeholder="Date"
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newdate"
                  no-title
                  color="#571964"
                  @input="(menu2 = false)"
                ></v-date-picker>
              </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-space-around py-10>
                <v-flex xs5>
                  <v-card
                    style="
                      box-shadow: -1px 5px 7px rgba(204, 204, 204, 0.5);
                      border-radius: 10px;"
              
                  >
                    <v-layout wrap pa-4>
                      <v-flex xs12 py-2 class="mainhead2">
                        THANKAM Per gm
                      </v-flex>
                      <v-layout wrap justify-end>
                        <v-flex xs8>
                          <v-text-field
                          placeholder="0"
                            class="my-text-field"
                            prefix="₹"
                            v-model="thankam"
                            type="number"
                            solo
                            flat
                            style="font-size: 30px"
                            dense
                            hide-details
                            height="60px"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs5>
                  <v-card
                    style="
                      box-shadow: -1px 5px 7px rgba(204, 204, 204, 0.5);
                      border-radius: 10px;
                    "
                  >
                    <v-layout wrap pa-4>
                      <v-flex xs12 py-2 class="mainhead2"> GOLD Per gm </v-flex>
                      <v-layout wrap justify-end>
                        <v-flex xs8>
                          <v-text-field
                          
                            placeholder="0"
                            class="my-text-field"
                            prefix="₹"
                            v-model="gold"
                            type="number"
                            style="font-size: 30px"
                            solo
                            flat
                            dense
                            hide-details
                            height="60px"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-space-around py-5>
                <v-flex xs5>
                  <v-card
                    style="
                      box-shadow: -1px 5px 7px rgba(204, 204, 204, 0.5);
                      border-radius: 10px;
                    "
                  >
                    <v-layout wrap pa-4>
                      <v-flex xs12 py-2 class="mainhead2">
                        SILVER Per gm
                      </v-flex>
                      <v-layout wrap justify-end>
                        <v-flex xs8>
                          <v-text-field
                          
                            v-model="silver"
                            type="number"
                            solo
                            prefix="₹"
                            placeholder="0"
                            class="my-text-field"
                            style="font-size: 30px"
                            flat
                            dense
                            height="60px"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs5>
                  <v-card
                    style="
                      box-shadow: -1px 5px 7px rgba(204, 204, 204, 0.5);
                      border-radius: 10px;
                    "
                  >
                    <v-layout wrap pa-4>
                      <v-flex xs12 py-2 class="mainhead2">
                        PLATINUM Per gm
                      </v-flex>
                      <v-layout wrap justify-end>
                        <v-flex xs8>
                          <v-text-field
                          class="my-text-field"
                            placeholder="0"
                            style="font-size: 30px"
                            prefix="₹"
                            v-model="platinum"
                            type="number"
                            solo
                            flat
                            height="60px"
                            dense
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-space-around py-5>
                <v-flex xs5>
                  <v-card
                    style="
                      box-shadow: -1px 5px 7px rgba(204, 204, 204, 0.5);
                      border-radius: 10px;
                    "
                  >
                    <v-layout wrap pa-4>
                      <v-flex xs12 py-2 class="mainhead2"> DIAMOND </v-flex>
                      <v-layout wrap justify-end>
                        <v-flex xs8>
                          <v-text-field
                          class="my-text-field"
                            placeholder="0"
                            prefix="₹"
                            style="font-size: 30px"
                            v-model="diamond"
                            type="number"
                            solo
                            dense
                            flat
                            hide-details
                            height="60px"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs5 align-self-center>
                  <v-layout wrap>
                    <v-flex xs2 align-self-end>
                      <v-btn
                        height="45px"
                        width="80px"
                        color="#3F053C"
                        class="buttons1"
                        dark
                        @click="EditRate()"
                        >Save</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      Pagelength: null,
      goldrate: 0,
      thankamrate: 0,
      silverrate: 0,
      platinumrate: 0,
      diamondrate: 0,
      menu2: false,

      // goldRate: 0,
      // silverRate: 0,
      // platinumRate: 0,
      // diamond: 0,
      // thankamRate: 0,

      thankam: 0,
      gold: 0,
      silver: 0,
      platinum: 0,
      diamond: 0,

      newdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dataList: {},
    };
  },
  mounted() {
    this.newdate= new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    if (this.newdate) {
      this.getBording();
    }
  },
  methods: {
    // AddRate() {
    //   axios({
    //     url: "/rate/add",
    //     method: "POST",
    //     data: {
    //       goldRate: this.goldRate,
    //       silverRate: this.silverRate,
    //       platinumRate: this.platinumRate,
    //       diamond: this.diamond,
    //       date: this.date,
    //       thankamRate: this.thankamRate,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //          this.getBording();
    //         this.goldrate = null;
    //         this.silverrate = null;
    //         this.platinumrate = null;
    //         this.diamondrate = null;
    //         this.date = null;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    EditRate() {
      axios({
        url: "/rate/add",
        method: "POST",
        data: {
          goldRate: this.gold,
          silverRate: this.silver,
          platinumRate: this.platinum,
          diamond: this.diamond,
          date: this.newdate,
          thankamRate: this.thankam,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getBording();
            this.goldrate = null;
            this.silverrate = null;
            this.platinumrate = null;
            this.diamondrate = null;
            this.date = null;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getBording() {
      console.log("1");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        
        params: {
          date: this.newdate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dataList = response.data.data;
          console.log("data=",this.dataList)
          this.thankam = response.data.data.thankamRate;
          this.gold = response.data.data.goldRate;
          this.silver = response.data.data.silverRate;
          this.platinum = response.data.data.platinumRate;
          this.diamond = response.data.data.diamond;
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    // boardR() {
    //   if (
    //     this.gold == "0" &&
    //     this.silver == "0" &&
    //     this.thankam == "0" &&
    //     this.platinum == "0" &&
    //     this.diamondr == "0"
    //   ) {
        
    //     this.AddRate();
    //   } else {
    //     console.log("gold", this.silver);
        
    //     this.EditRate();
    //   }
    // },
  },
};
</script>
<style scoped>
.shadowwhite {
  box-shadow: 10px 11px 16px rgba(210, 18, 18, 0.62);
}

</style>
